.snackbar{
    @apply fixed z-30 bottom-10 left-1/2 w-max max-w-md  transition-transform p-4 rounded-2xl flex justify-center items-center;
    &.error{
        background: linear-gradient(342deg, rgba(217, 70, 70, 0.15) -12.5%, rgba(239, 38, 38, 0.15) 102.88%);
       }
    &.success{
        @apply bg-gradient-to-t from-lime-600 to-lime-500;
       }
    &.caution{
        background: linear-gradient(342deg, rgba(216, 216, 73, 0.25) -12.5%, rgba(158, 162, 34, 0.25) 102.88%)
       }
       &.show{
        transform:translateX(-50%) translateY(0px);
       }
       &.hide{
        transform:translateX(-50%) translateY(calc(100% + 44px));
       }
}