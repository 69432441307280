@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
html {
  color: #e6e1e5;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.15px;
}
textarea{
  resize: none;
}
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #938f99;
  border-radius: 100px;
}

/* UI elements styles */
.blurred-light,.blurred-dark{
  position:  relative;
&.task,&.select,&.dropdown{
  &:after{
    z-index: -1;
  }
} 
  &:after{
    content: '';
    @apply absolute w-full h-full;
    z-index: -2;
    inset: 0 0 0 0; border-radius: inherit;
  }
}
.blurred-light {
  
  &:after{
    background: linear-gradient(
    342deg,
    rgba(168, 168, 168, 0.1) -12.5%,
    rgba(158, 158, 158, 0.1) 102.88%
  );
  backdrop-filter: blur(22px);
  }
}
.blurred-dark {
  &::after{
    background: linear-gradient(
    342deg,
    rgba(255, 255, 255, 0.02) -12.5%,
    rgba(255, 255, 255, 0.03) 102.88%
  );
  backdrop-filter: blur(100px);
    
  }
}

.gradient-highlighted{
  background:  linear-gradient(342deg, rgba(255, 255, 255, 0.22) -12.5%, rgba(81, 81, 81, 0.22) 102.88%);
}
.HeaderTabs {
  .active {
    border-color: #938f99;
  }
}
.button_create{
  @apply border border-dashed border-sakura-100 text-sakura-100 bg-transparent hover:bg-white/10 m-px cursor-pointer;
}
.square{
  scale: 0.6;
}
.polygon{
  scale: 0.7;
  clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
}
.circle{
  scale: 0.7;
  clip-path: circle(50% at 50% 50%);
}
.triangle{ scale: 0.8;
  clip-path: polygon(50% 7%, 0 93%, 100% 93%);
}