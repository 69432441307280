.background{
    @apply w-full h-full flex-grow flex flex-col;
    background:  url('../assets/sign-in.jpeg');
    background-position: 50% 37%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #1C1B1F;
}
  .innerLayout{
    @apply w-full h-full grid;
    z-index: 2;
    grid-template-columns: 1.07fr 1fr;
    
  }
