.resultsWrapper{
    display: grid;
    grid-template-rows: 0fr;
    transition: all 500ms;
    opacity: 0;
    &>div{ 
        overflow: hidden;
    }
    &.expand{
        grid-template-rows: 1fr;
        opacity: 1;
        &>div{
            max-height: 140px;
            overflow-y: auto;
            margin: 12px 0;
        }
    }
    .card{
        @apply w-full text-left;
        padding: 8px 24px 8px 16px;
        height: 72px;
        display: grid;
        grid-template-columns: 40px 1fr;
        column-gap: 16px;
        line-height: 20px;
        transition: background-color 0.5s;
        &:hover{
            @apply bg-white/5;
        }
        .icon{
            @apply row-span-2 place-content-center self-center;
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
       
        .cardStatus{
            @apply  overflow-hidden overflow-ellipsis whitespace-nowrap;
        }
        .cardName{
          @apply text-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
        }
    }
}