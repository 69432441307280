.block{
    @apply p-4 rounded-2xl min-w-max text-sm flex flex-col gap-2;
    height: 190px;
    max-width: 168px;
    background: linear-gradient(342deg, rgba(168, 168, 168, 0.20) -12.5%, rgba(81, 81, 81, 0.20) 102.88%);
    backdrop-filter: blur(100px);
    letter-spacing: 0.25px;
    color: #E6E1E5;
    border:1px solid transparent;
    @media (min-width:1024px) {
        width: 168px;
        
    }
    
    .icon{
        @apply w-full h-14;
    }
    .data{ 
        @apply mb-auto;
        font-size: calc(34rem / 16);
        line-height: 1.06;
    }
   &.fill-rate{
    background: linear-gradient(342deg, rgba(125, 42, 42, 0.05) -12.5%, rgba(114, 24, 24, 0.15) 102.88%);
   }
   &.battery{
    background: linear-gradient(342deg, rgba(42, 125, 50, 0.05) -12.5%, rgba(34, 162, 75, 0.15) 102.88%);
   }
   &.devices{
    background: linear-gradient(342deg, rgba(216, 216, 73, 0.05) -12.5%, rgba(158, 162, 34, 0.15) 102.88%)
   }
   &.create-widget{
    @apply border-sakura-100 flex-row items-center justify-center;
    background: transparent;
    border: 1px dashed ;
    .icon{
        @apply h-6 w-max -order-1; 
    }
   }
}