.container{
    @apply w-full  flex rounded-2xl overflow-hidden;
    border: 1px solid #79747E;
}
.tab{
    @apply w-1/2 flex-grow flex items-center justify-center gap-2 py-2.5 px-3 relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: 0.1px;
    transition: all .4s;
    background: linear-gradient(342deg, rgba(81, 81, 81, 0.0) -12.5%, rgba(81, 81, 81, 0.0) 102.88%);
    &:not(:last-child)::after{
        content: "";
      @apply w-px h-full absolute right-0 top-0;
      background: #79747E;
    }
    &.current{
    background: linear-gradient(342deg, rgba(81, 81, 81, 0.20) -12.5%, rgba(81, 81, 81, 0.33) 102.88%);
    }
    &:not(.current):hover{
        background: linear-gradient(342deg, rgba(81, 81, 81, 0.05) -12.5%, rgba(81, 81, 81, 0.13) 102.88%);
        }
}