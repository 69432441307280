.mainLayout{
    @apply w-full h-full flex-grow flex flex-col;
    
    // background: linear-gradient(89deg, #15131B 11.07%, rgba(0, 0, 0, 0.00) 63.1%), url('../assets/main-bg.png'), black 50% / cover no-repeat;
background-position: 50% 37%;
background-size: cover;
background-repeat: no-repeat;
background-color: #1C1B1F;
background: #536976;  
background: linear-gradient(to top, #120d18, #283138); 

}
.mainLayout::before {
    content: "";
    @apply absolute top-0 left-0 w-full h-full bg-black;
    opacity: 0.15; 
    z-index: 1; 
  }
  .container{
    @apply w-full xl:w-4/5 mx-auto px-5 xl:px-0 flex flex-col flex-grow gap-6 xl:gap-12 h-full;
   z-index: 2;
    padding-top: 60px ;
    padding-bottom: 50px;
  }
  .innerLayout{
    @apply w-full h-full flex flex-col flex-grow gap-6 xl:gap-12 relative;
   
    
  }
