.App {
  @apply flex flex-col;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
}

.container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.canvas {
  pointer-events: none;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.view1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.view2 {
  pointer-events: all;
  position: absolute;
  top: 2em;
  right: 2em;
  width: calc(250px - 2em);
  height: calc(250px - 2em);
}
.device {
  filter: url(#round);
  @apply transition-all;
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #796c98;
    // filter: url(#round);
    clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 10px), 50% 100%, 0% calc(100% - 10px));
  }
  &.alert::before {
    background: rgba(244, 73, 73, 1);
  }
  &.caution::before {
    background: rgb(255, 251, 1);
  }
  &.caution {
    animation: jump 0.5s ease-in-out alternate-reverse infinite;
  }
  &.alert {
    animation: jump 0.5s ease-in-out alternate-reverse infinite;
  }
  .inner-bg {
    // filter: url(#round);
    filter: url(#round2);
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: #d0bcff;
      clip-path: polygon(
        0 0,
        100% 0%,
        100% calc(100% - 9.5px),
        50% calc(100% - 0.5px),
        0% calc(100% - 9.5px)
      );
    }
  }
}
.counter {
  @apply bg-orange-500;
}
.thermal {
  @apply bg-purple-500;
}
.ray {
  @apply bg-yellow-300;
}
.text-stroke {
  text-shadow: 0px 0px 1px #000000;
  // -webkit-text-stroke: 0.1px black;
}
@keyframes jump {
  0% {
    transform: translateX(-50%) translateY(-100%);
  }
  100% {
    transform: translateX(-50%) translateY(-105%);
  }
}
