.board{
    @apply p-4 w-full h-full overflow-hidden relative;
   border-radius: 24px;
    // &::before{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     border-radius: 24px;
    //     background: linear-gradient(342deg, rgba(255, 255, 255, 0.02) -12.5%, rgba(255, 255, 255, 0.03) 102.88%);
    //     backdrop-filter: blur(100px);
    //   }
      .inner{
        @apply grid grid-cols-3  gap-3 h-full overflow-hidden ;
        width: calc(100% - 64px);
      }
}