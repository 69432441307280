.wrapper {
	@apply w-full relative h-14 px-3 py-2 flex flex-col justify-end border-b-2 border-b-sakura-100;
    background: #49454F;
    border-radius: 4px 4px 0 0;
        label {
            @apply absolute w-full h-full top-0 left-0 pl-3 flex items-center transition-all duration-300 text-lg z-0;
        }
        input:not(:placeholder-shown) + label {
            @apply h-full pl-3 text-sakura-100 transform -translate-y-3 text-xs;
          }
        input {
            @apply w-full h-6 focus:outline-none bg-transparent placeholder-shown:placeholder-transparent text-sakura-50 caret-sakura-100 pointer-events-auto;
            z-index: 1;
            position: relative;
            background: rgba(0,0,0,0); 
            &:valid + label,
            &:focus + label {
            @apply h-full pl-3 text-sakura-100 transform -translate-y-3 text-xs;
                
            }
        }
        input:-webkit-autofill {
            @apply text-sakura-50 text-base;
            -webkit-text-fill-color:#E8DEF8;
            color:#E8DEF8;
            /* Set your custom background color */
            box-shadow: 0 0 0 1000px #49454F inset; /* Remove autofill shadow (optional) */
            outline: none;
            border: none;
          }
          input:-webkit-autofill + label{
            @apply h-full pl-3 text-sakura-100 transform -translate-y-3 text-xs;
          }
}