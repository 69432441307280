.blurred{ 
    position: relative;
border-radius: 16px;
background: linear-gradient(342deg, rgba(168, 168, 168, 0.30) -12.5%, rgba(158, 158, 158, 0.30) 102.88%);
backdrop-filter: blur(22px);
border: 1px solid transparent;
    span{
        @apply text-sakura-50;
    }
    
    &.alerts{
    background: linear-gradient(342deg, rgba(255, 66, 39, 0.30) -12.5%, rgba(255, 66, 39, 0.30) 102.88%);
    backdrop-filter: blur(6px);
    }
    &.add{
    background: transparent;
    border: 1px dashed #938F99;
    backdrop-filter: blur(0px);
        span{
            @apply text-sakura-100
        }
    }
    &::after{
        content: "";
        @apply absolute w-full h-full top-0 left-0  ;
        z-index: -1;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.0);
        transition: all 0.3s;
       }
    &:hover {
       &::after{
       
        background: rgba(0, 0, 0, 0.3);
       }
      }

}