.task{
    @apply grid w-full  p-3 pr-1 gap-1 flex-shrink-0 relative transition-opacity;

    grid-template-columns: 24px 1fr 24px 24px;
    opacity: 1;
    &.isDragged{
            background: linear-gradient(
              342deg,
              rgba(168, 168, 168, 0.53) -12.5%,
              rgba(158, 158, 158, 0.53) 102.88%
            );
           
    }
    &.isGhosting{
        opacity: .7;
        background: linear-gradient(
          342deg,
          rgba(56,30, 114, 0.33) -12.5%,
          rgba(158, 158, 158, 0.33) 102.88%
        );
       
}
    .badge{
        @apply w-5 h-5 flex justify-center items-center text-xs bg-peach text-sakura-900 absolute -top-2 -right-2 transition-opacity pointer-events-none rounded-full;
        display: none;
        &.showBadge{
            opacity: 1;
            display: flex;
        }
    }
}