.signalQuality[data-quality]{
    &::before{
        content: attr(data-quality);
    }
    text-transform: capitalize;
    &[data-quality="excellent"]{
    @apply text-lime-400;
    }
    &[data-quality="good"]{
        @apply text-lime-200;
    }
    &[data-quality="moderate"]{
        @apply text-yellow-300;
    }
    &[data-quality="poor"]{
        @apply text-red-300;
    }
    &[data-quality="no signal"]{
        @apply text-red-500;
    }
}