.card{
    @apply w-full text-left ;
    padding: 8px 16px;
    height: 72px;
    display: grid;
    grid-template-columns: 40px 1fr 40px 40px;
    column-gap: 16px;
    line-height: 20px;
    transition: background-color 0.5s;
   
  align-items: center; 
  &.alerts{
    background: linear-gradient(342deg, rgba(255, 66, 39, 0.10) -12.5%, rgba(255, 66, 39, 0.10) 102.88%);
    backdrop-filter: blur(6px);
  }
    &:hover{
        @apply bg-white/5;
    }
    .icon{
        @apply row-span-1 place-content-center self-center;
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
   
    .cardStatus{
        @apply  overflow-hidden overflow-ellipsis whitespace-nowrap;
    }
    .cardName{
      @apply text-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
    }
}