.hero_section{
@apply w-full h-full flex flex-col items-center justify-center p-5 xl:p-10;
h1,h3{
    text-align: left;
}
h1{
    max-width: 768px;
}
h3{
    max-width: 585px
}
}
.form_section{
@apply w-full h-full flex flex-col justify-center items-center;
background: linear-gradient(166deg, rgba(0, 0, 0, 0.08) 5.62%, rgba(0, 0, 0, 0.13) 95.28%);
backdrop-filter: blur(75px);
.form{
    @apply w-full flex flex-col gap-4 lg:gap-8;
    max-width: 400px;
}
}